// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_checkBox__Zev\\+r {\n  display: flex;\n  align-items: center;\n}\n.styles_checkBox__Zev\\+r img {\n  max-width: 25px;\n  cursor: pointer;\n}\n.styles_checkBox__Zev\\+r span {\n  font-family: \"poppins-regular\";\n  font-style: normal;\n  margin-left: 15px;\n  font-size: 16px;\n  line-height: 24px;\n  cursor: pointer;\n  /* or 120% */\n  color: #000000;\n}", "",{"version":3,"sources":["webpack://./src/components/common/checkbox/styles.module.scss","webpack://./src/styles/theme/_variables.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;EACA,eAAA;AAAJ;AAEE;EACE,8BAAA;EACA,kBAAA;EAEA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EAEA,cCZS;ADUb","sourcesContent":["@import \"../../../styles/theme/variables\";\n.checkBox {\n  display: flex;\n  align-items: center;\n\n  img {\n    max-width: 25px;\n    cursor: pointer;\n  }\n  span {\n    font-family: \"poppins-regular\";\n    font-style: normal;\n\n    margin-left: 15px;\n    font-size: 16px;\n    line-height: 24px;\n    cursor: pointer;\n    /* or 120% */\n\n    color: $black-color;\n  }\n}\n","\n\n\n$primary-color:#00CFC5;\n$primary-dark:#01A19A;\n$secondary-color:#05152E;\n$white-color:#FFFFFF;\n$black-color:#000000;\n$grey-color:#9A9696;\n$red-color:#FD8383;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": "styles_checkBox__Zev+r"
};
export default ___CSS_LOADER_EXPORT___;
