// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_faqCard__dvGGW {\n  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);\n  border-radius: 16px;\n  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);\n  padding: 25px 23px;\n  max-width: 400px;\n}\n.styles_faqCard__dvGGW .styles_cardHeader__v3i\\+b {\n  display: flex;\n  align-items: start;\n  justify-content: space-between;\n}\n.styles_faqCard__dvGGW .styles_cardHeader__v3i\\+b .styles_btnToggler__pIq3V {\n  padding: 0;\n  min-width: 30px;\n}\n.styles_faqCard__dvGGW .styles_cardBody__7uQ0F {\n  padding-top: 10px;\n}\n.styles_faqCard__dvGGW .styles_cardBody__7uQ0F p {\n  font-size: 14px;\n  color: #898989;\n}", "",{"version":3,"sources":["webpack://./src/components/helpdesk/single-faqs-card/styles.module.scss"],"names":[],"mappings":"AACA;EAEE,8CAAA;EACA,mBAAA;EACA,8CAAA;EACA,kBAAA;EACA,gBAAA;AADF;AAEE;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;AAAJ;AACI;EACE,UAAA;EACA,eAAA;AACN;AAEE;EACE,iBAAA;AAAJ;AACI;EACE,eAAA;EACA,cAAA;AACN","sourcesContent":["@import \"../../../styles/theme/variables\";\n.faqCard {\n  // background: $white-color;\n  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);\n  border-radius: 16px;\n  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);\n  padding: 25px 23px;\n  max-width: 400px;\n  .cardHeader {\n    display: flex;\n    align-items: start;\n    justify-content: space-between;\n    .btnToggler {\n      padding: 0;\n      min-width: 30px;\n    }\n  }\n  .cardBody {\n    padding-top: 10px;\n    p {\n      font-size: 14px;\n      color: #898989;\n      // text-align: justify;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faqCard": "styles_faqCard__dvGGW",
	"cardHeader": "styles_cardHeader__v3i+b",
	"btnToggler": "styles_btnToggler__pIq3V",
	"cardBody": "styles_cardBody__7uQ0F"
};
export default ___CSS_LOADER_EXPORT___;
