// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_select__VRBTN {\n  max-width: 350px;\n  margin: auto;\n  margin-top: 20px;\n}\n@media screen and (max-width: 991px) {\n  .styles_select__VRBTN {\n    margin-top: 12px;\n  }\n}\n.styles_select__VRBTN label {\n  font-family: \"poppins-light\";\n  font-style: normal;\n  font-size: 15px;\n  line-height: 22px;\n  /* identical to box height */\n  display: block;\n  letter-spacing: 0.025em;\n  color: #000000;\n  margin-bottom: 4px;\n}\n.styles_select__VRBTN ~ span {\n  color: red;\n  font-size: small;\n}", "",{"version":3,"sources":["webpack://./src/components/common/Select/styles.module.scss","webpack://./src/styles/theme/_variables.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,YAAA;EACA,gBAAA;AAAF;AACE;EAJF;IAKI,gBAAA;EAEF;AACF;AADE;EACE,4BAAA;EACA,kBAAA;EAEA,eAAA;EACA,iBAAA;EACA,4BAAA;EACA,cAAA;EAEA,uBAAA;EAEA,cCZS;EDaT,kBAAA;AAAJ;AAEE;EACE,UAAA;EACA,gBAAA;AAAJ","sourcesContent":["@import \"../../../styles/theme/variables\";\n.select {\n  max-width: 350px;\n  margin: auto;\n  margin-top: 20px;\n  @media screen and (max-width: 991px) {\n    margin-top: 12px;\n  }\n  label {\n    font-family: \"poppins-light\";\n    font-style: normal;\n\n    font-size: 15px;\n    line-height: 22px;\n    /* identical to box height */\n    display: block;\n\n    letter-spacing: 0.025em;\n\n    color: $black-color;\n    margin-bottom: 4px;\n  }\n  & ~ span {\n    color: red;\n    font-size: small;\n  }\n}\n","\n\n\n$primary-color:#00CFC5;\n$primary-dark:#01A19A;\n$secondary-color:#05152E;\n$white-color:#FFFFFF;\n$black-color:#000000;\n$grey-color:#9A9696;\n$red-color:#FD8383;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "styles_select__VRBTN"
};
export default ___CSS_LOADER_EXPORT___;
