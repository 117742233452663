// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_jobSalaryWrapper__j\\+jd8 {\n  margin-top: 15px;\n}\n.styles_jobSalaryWrapper__j\\+jd8 label {\n  font-family: \"poppins-bold\";\n  font-size: 14px;\n  line-height: 22px;\n  color: #9f9f9f;\n  margin-bottom: 4px;\n}\n.styles_jobSalaryWrapper__j\\+jd8 .styles_jobSalary__ByreX {\n  display: flex;\n  align-items: center;\n}\n.styles_jobSalaryWrapper__j\\+jd8 .styles_jobSalary__ByreX .styles_salarySelect__VPodR {\n  max-width: 70px;\n  min-width: 70px;\n}\n.styles_jobSalaryWrapper__j\\+jd8 .styles_jobSalary__ByreX .styles_createJobInp__vqYP4 {\n  min-width: unset;\n  max-width: 100%;\n  width: 100%;\n}\n.styles_jobSalaryWrapper__j\\+jd8 .styles_jobSalary__ByreX .styles_createJobInp__vqYP4 input {\n  border: 2px solid #898989;\n  border-radius: 3px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/SalarySelect/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAE;EACE,2BAAA;EAEA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AAAI;EACE,eAAA;EACA,eAAA;AAEN;AAAI;EACE,gBAAA;EACA,eAAA;EACA,WAAA;AAEN;AADM;EACE,yBAAA;EACA,kBAAA;AAGR","sourcesContent":[".jobSalaryWrapper {\n  margin-top: 15px;\n  label {\n    font-family: \"poppins-bold\";\n\n    font-size: 14px;\n    line-height: 22px;\n    color: #9f9f9f;\n    margin-bottom: 4px;\n  }\n  .jobSalary {\n    display: flex;\n    align-items: center;\n    .salarySelect {\n      max-width: 70px;\n      min-width: 70px;\n    }\n    .createJobInp {\n      min-width: unset;\n      max-width: 100%;\n      width: 100%;\n      input {\n        border: 2px solid #898989;\n        border-radius: 3px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobSalaryWrapper": "styles_jobSalaryWrapper__j+jd8",
	"jobSalary": "styles_jobSalary__ByreX",
	"salarySelect": "styles_salarySelect__VPodR",
	"createJobInp": "styles_createJobInp__vqYP4"
};
export default ___CSS_LOADER_EXPORT___;
