import { DELETE_CHECKBOX } from "../constants/constants";
import { v4 as uuid } from "uuid";
import $ from "jquery";
export const Deletecheckboxmain = (index, getcheckbox) => {
  return {
    type: DELETE_CHECKBOX,
    payload: getcheckbox,
    index: index,
  };
};
