import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { Button, Grid } from "@mui/material";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  addProfileLicense,
  recruitmentIndustry,
} from "../../../../../api/candidate/candidate.class";
import CustomSelect from "../../../../common/Select";
import ImageUploader from "../../../../common/ImageUploader";
import Alerts from "../../../../common/Alert";
import TextInput from "../../../../common/TextInput";

export default function CustomModal({
  openModal,
  setOpenModal,
  isAddition,
  userDetailData,
  data,
}) {
  const handleClose = () => setOpenModal(false);

  const auth = useSelector((state) => state?.auth?.results);

  const [recruitmentIndustries, setRecruitmentIndustries] = useState();

  const [recruitmentOptions, setRecruitmentOptions] = useState([]);
  const [CertLicenseOptions, setCertLicenseOptions] = useState([]);
  const [edit1, setEdit1] = useState(true);
  const [edit2, setEdit2] = useState(true);
  const [file, setFile] = useState({
    front_image: data?.front_image,
    back_image: data?.back_image,
  });

  const authProfile = useSelector((state) => state?.rprofile);

  useEffect(() => {
    const API = async () => {
      try {
        const respone = await recruitmentIndustry();
        setRecruitmentIndustries(respone.data.results);
      } catch (error) {
        console.log(error);
      }
    };
    API();
  }, []);

  useEffect(() => {
    if (recruitmentIndustries?.length > 0) {
      recruitmentIndustries.forEach((record, index) => {
        const data = [{ value: record.id, label: record.name, index: index }];
        setRecruitmentOptions((prev) => [...prev, ...data]);
      });
    }
    return () => {
      setRecruitmentOptions("");
    };
  }, [recruitmentIndustries]);

  const handleRecruitment = (setFieldValue, option, values) => {
    if (values.recruit_industry === "") {
      setFieldValue("recruit_industry", option);
    } else {
      setCertLicenseOptions([]);
      setFieldValue("recruit_industry", option);
    }

    if (recruitmentOptions !== null && recruitmentIndustries?.length > 0) {
      recruitmentIndustries[
        option.index
      ]?.industry_certification_license?.forEach((record, index) => {
        const data = [{ value: record.id, label: record.name, index: index }];
        setCertLicenseOptions((prev) => [...prev, ...data]);
      });
    }
  };

  const handleCertLicense = (setFieldValue, option) => {
    setFieldValue("industry_cert_license", option);
  };

  const initialValues = {
    recruit_industry: "",
    industry_cert_license: "",
    issue_date: "",
    expiry_date: "",
    doc_id: "",
    first_name: authProfile?.profile?.results?.first_name,
    last_name: authProfile?.profile?.results?.last_name,
    dob: "",
    mail_id: authProfile?.profile?.results?.email,
    state: "",
    country: "",
    // passportNumber: "",
  };

  const licenseSchema = Yup.object().shape({
    recruit_industry: Yup.object().required("Enter Recruitment Industries"),
    industry_cert_license: Yup.object().required("Enter Certification License"),
    issue_date: Yup.string()
      .required("Enter Issue Date")
      .test(
        "futureDate",
        "Issue Date cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "DD/MM/YYYY");
          const currentDate = moment();
          return selectedDate.isBefore(currentDate);
        }
      ),
    expiry_date: Yup.string().required("Enter Expiry Date"),
    doc_id: Yup.string().required("Enter Document ID"),
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    dob: Yup.string()
      .required("Enter Date of Birth")
      .test(
        "futureDate",
        "Date of Birth cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "DD/MM/YYYY");
          const currentDate = moment();
          return selectedDate.isBefore(currentDate);
        }
      ),
    mail_id: Yup.string().required("Enter Email"),
    country: Yup.string().required("Enter Country"),
  });

  const onSubmit = async (values) => {
    try {
      const payload = {
        candidate_id: auth?.candidate_id,
        request_type: "candidate",
        document_number: values.doc_id,
        first_name: authProfile?.profile?.results?.first_name,
        last_name: authProfile?.profile?.results?.last_name,
        dob: moment(values?.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
        state: values?.state,
        country: values?.country,
        // passportNumber: values?.passportNumber,
        mail_id: authProfile?.profile?.results?.email,
        issue_date: moment(values.issue_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        expiry_date: moment(values.expiry_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        candidate_lic_cert_id: userDetailData?.id || "",
        "recruitment_industries[recruitment_industry_1][rec_ind_request_id]":
          values.recruit_industry?.value || "",
        "recruitment_industries[recruitment_industry_1][ind_cert_lic_request_id]":
          values.industry_cert_license?.value || "",
        "recruitment_industries[recruitment_industry_1][front_image]":
          file?.front_image,
        "recruitment_industries[recruitment_industry_1][back_image]":
          file?.back_image,
        "recruitment_industries[recruitment_industry_1][issue_date]":
          moment(values.issue_date, "DD/MM/YYYY").format("YYYY-MM-DD") || "",
        "recruitment_industries[recruitment_industry_1][expiry_date]":
          moment(values.expiry_date, "DD/MM/YYYY").format("YYYY-MM-DD") || "",
        "recruitment_industries[recruitment_industry_1][document_number]":
          values.doc_id || "",
        "recruitment_industries[recruitment_industry_1][first_name]":
          values.first_name || "",
        "recruitment_industries[recruitment_industry_1][last_name]":
          values.last_name || "",
        "recruitment_industries[recruitment_industry_1][dob]":
          moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD") || "",
        "recruitment_industries[recruitment_industry_1][mail_id]":
          values.mail_id || "",
        "recruitment_industries[recruitment_industry_1][state]":
          values.state || "",
        "recruitment_industries[recruitment_industry_1][country]":
          values.country || "",
      };

      const response = await addProfileLicense(payload);

      if (response.data.success) {
        handleClose();
        isAddition(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}>
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Add Certificates and Licenses
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={licenseSchema}
              onSubmit={onSubmit}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className={styles.formsBox}>
                  <Box className={styles.statusbox}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          compolsory={true}
                          customClass={styles.input}
                          label="First Name"
                          type="text"
                          name="first_name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="First Name"
                          onChange={handleChange}
                          value={values?.first_name}
                        />
                        <span>
                          {errors.first_name &&
                            touched.first_name &&
                            errors.first_name}
                        </span>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          compolsory={true}
                          customClass={styles.input}
                          label="Last Name"
                          type="text"
                          name="last_name"
                          // sx={{ mt: 5.6, pb: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={values?.last_name}
                        />
                        <span>
                          {errors.last_name &&
                            touched.last_name &&
                            errors.last_name}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          style={{ width: "100%" }}
                          customClass={styles.input}
                          label={
                            <>
                              Email
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          type="email"
                          name="mail_id"
                          // sx={{ mt: 5.6, pb: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          value={values?.email}
                        />
                        <span>
                          {errors.first_name &&
                            touched.first_name &&
                            errors.first_name}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <TextInput
                          compolsory={true}
                          label="Date of birth"
                          type="date"
                          name="dob"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.dob}
                        /> */}
                        <div className={styles.datePicker}>
                          <label>
                            Date of birth<span style={{ color: "red" }}>*</span>
                          </label>
                          <DatePicker
                            customClass={styles.boxForm}
                            sx={{ maxWidth: "initial" }}
                            name="dob"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "dob",
                                  value: moment(date).format("DD/MM/YYYY"),
                                },
                              })
                            }
                            value={values.dob}
                            placeholderText="Select Date"
                          />
                        </div>
                        <span>{errors.dob && touched.dob && errors.dob}</span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          label="State"
                          type="text"
                          name="state"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.state}
                        />
                        <span>
                          {errors.state && touched.state && errors.state}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          compolsory={true}
                          label="Country"
                          type="text"
                          name="country"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.country}
                        />
                        <span>
                          {errors.country && touched.country && errors.country}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="recruit_industry"
                            onChange={(option) =>
                              handleRecruitment(setFieldValue, option, values)
                            }
                            value={values.recruit_industry}
                            options={recruitmentOptions}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Industries&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                          />
                          <span>
                            {errors.recruit_industry &&
                              touched.recruit_industry &&
                              errors.recruit_industry}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="industry_cert_license"
                            onChange={(option) =>
                              handleCertLicense(setFieldValue, option, values)
                            }
                            value={values.industry_cert_license}
                            options={CertLicenseOptions}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Industry Certification License&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                          />
                          <span>
                            {errors.industry_cert_license &&
                              touched.industry_cert_license &&
                              errors.industry_cert_license}
                          </span>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          style={{ width: "100%" }}
                          customClass={styles.input}
                          label={
                            <>
                              Document ID&nbsp;
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          type="text"
                          name="doc_id"
                          // sx={{ mt: 5.6, pb: 1 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          value={values.doc_id}
                        />
                        <span>
                          {errors.doc_id && touched.doc_id && errors.doc_id}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <TextInput
                          // customClass={styles.boxForm}
                          label={
                            <>
                              Issue Date&nbsp;
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          type="date"
                          name="issue_date"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.issue_date}
                        /> */}
                        <div className={styles.datePicker}>
                          <label>
                            Issue Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <DatePicker
                            customClass={styles.boxForm}
                            sx={{ maxWidth: "initial" }}
                            name="issue_date"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "issue_date",
                                  value: moment(date).format("DD/MM/YYYY"),
                                },
                              })
                            }
                            value={values.issue_date}
                            placeholderText="Select Date"
                          />
                        </div>
                        <span>
                          {errors.issue_date &&
                            touched.issue_date &&
                            errors.issue_date}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <TextInput
                          // customClass={styles.boxForm}
                          label={
                            <>
                              Expiry Date&nbsp;
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          type="date"
                          name="expiry_date"
                          // sx={{ maxWidth: "initial" }}
                          onChange={handleChange}
                          value={values.expiry_date}
                        /> */}
                        <div className={styles.datePicker}>
                          <label>
                            Expiry Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <DatePicker
                            customClass={styles.boxForm}
                            sx={{ maxWidth: "initial" }}
                            name="expiry_date"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "expiry_date",
                                  value: moment(date).format("DD/MM/YYYY"),
                                },
                              })
                            }
                            value={values.expiry_date}
                            placeholderText="Select Date"
                          />
                        </div>
                        <span>
                          {errors.expiry_date &&
                            touched.expiry_date &&
                            errors.expiry_date}
                        </span>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className={styles.parentBox}>
                          <ImageUploader
                            label={
                              <>
                                <span>Front Side Image</span>
                              </>
                            }
                            valuenew={file?.front_image || data?.front_image}
                            accept={"image/*"}
                            edit={edit1}
                            onChange={(e) => {
                              // setFieldValue("front_image", e.target.files[0]);
                              setEdit1(false);
                              setFile({
                                ...file,
                                front_image: e.target.files[0],
                              });
                            }}
                            name="front_image"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className={styles.parentBox}>
                          <ImageUploader
                            label={
                              <>
                                <span>Back Side Image</span>
                              </>
                            }
                            valuenew={file?.back_image || data?.back_image}
                            accept={"image/*"}
                            edit={edit2}
                            onChange={(e) => {
                              // setFieldValue("back_image", e.target.files[0]);
                              setEdit2(false);
                              setFile({
                                ...file,
                                back_image: e.target.files[0],
                              });
                            }}
                            name="back_image"
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box className={`${styles.button} button-primary`}>
                      <Button type="submit">Save</Button>
                      <Button className="secondary-btn" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
