import { FORM_BUILDER_IMAGE } from "../constants/constants";

export const storeFormbuilderimage = (type, value) => {
  switch (type) {
    case FORM_BUILDER_IMAGE:
      return {
        type: FORM_BUILDER_IMAGE,
        payload: value,
      };

    default:
      return value;
  }
};
