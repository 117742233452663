import CryptoJS from "crypto-js";
import * as flatted from "flatted";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { Box, CircularProgress } from "@mui/material";

// import useLocalStorage from "use-local-storage";
import { useNavigate } from "react-router-dom";
import S3FileUpload from "react-s3";

import Calender from "../../../assets/sinner/calender.svg";
import CheckBoxMenu from "../../../assets/sinner/check-box-menu.png";
import Initials from "../../../assets/sinner/initials.svg";
import Signature from "../../../assets/sinner/signature.svg";
import TextInput from "../../../assets/sinner/text_input.svg";
import style from "../../document_sinner/styles.module.scss";
import { routes } from "../../../routes";
import {
  ALERT_MESSAGE_TEXT,
  DOCUMENT_SIGNING,
  FieldTypes,
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../../store/constants/constants";
import { uid } from "../../../utils/helpers";
import s3Config, { getFile, uploadFile } from "../../../utils/s3Config";
import LoaderGif from "../../../assets/common/loader150.gif";
import { setUploadPdf } from "../../../store/actions/pdfupload.actions";
import { setSigningMetadata } from "../../../store/actions/signingMetadata.actions";
// import { storeSigningService } from "../../services/docSign.services";
import { EDH } from "../../../utils/EDH";
import { storeSigningService } from "../../../api/sinner/SinnerApi";
import { ShowAlert } from "../../../store/actions/alertActions";

/**
 *
 *
 *
 */

const AssignFieldsLeftColumn = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const pdfMetadata = useSelector((s) => s.signingMetadataReducer);
  const pdfFile = useSelector((state) => state.pdfUploadReducer).pdfFile;
  const [successOpenModel, setSuccessOpenModel] = useState(false);
  const [processSuccessful, setProcessSuccess] = useState(null);
  const signingType = useSelector(
    (state) => state.pdfUploadReducer
  ).signingType;
  // const [userLocal] = useLocalStorage(KEY_USER);
  // const [message, setMessage] = useLocalStorage(ALERT_MESSAGE_TEXT);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const userLocal = JSON.parse(localStorage.getItem("auth"))?.results || {};

  console.log(singleCandidate);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const [docInfo, setDocInfo] = useState({
    title: "",
    desc: "",
    password: "",
    cnfPassword: "",
    emailPasswordToMe: false,
    emailPasswordToSigners: false,
  });

  const [errors, setErrors] = useState(null);

  const onDocInfoChange = (e) => {
    setDocInfo({ ...docInfo, [e.target.name]: e.target.value });
  };

  React.useImperativeHandle(ref, () => ({
    sendDocs() {
      setOpen(true);
    },
  }));

  const convertToBase64 = (pdfFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdfFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  console.log("pdfMetadatasssss", pdfMetadata);
  const handleMetadataSubmit = async (e) => {
    console.log("insidehandle meme");
    e.preventDefault();
    setErrors(null);
    let err = null;
    if (docInfo.title.trim() === "") {
      err = {
        ...err,
        title: "Title is required",
      };
    } else if (docInfo.title.length < 3) {
      err = {
        ...err,
        title: "Title should be at least 3 characters",
      };
    } else if (docInfo.title.length > 64) {
      err = {
        ...err,
        title: "Title should be maximum 64 characters",
      };
    }

    if (docInfo.password.length < 6) {
      err = {
        ...err,
        password: "Password should be at least 6 characters",
      };
    }

    if (docInfo.cnfPassword !== docInfo.password) {
      err = {
        ...err,
        cnfPassword: "Password and confirm password has not match",
      };
    }

    setErrors({ ...err });

    if (err) {
      return;
    }

    // store pdf to s3
    const metadata = {};
    metadata.sessionId = uid();
    const senderBlob = {
      ...pdfMetadata,
    };

    const senderMetadata = {
      ...metadata,
      isSender: true,
      isMain: true,
    };

    // encrypt pdf and upload to s3 and then

    try {
      dispatch({
        type: LOADING_TRUE,
      });
      setSuccessOpenModel(true);
      const encFile = await EDH.encryptFileAsync(pdfFile, docInfo.password);
      console.log(encFile);
      const pdfLocation = `media/documentSigning/pdf/${userLocal.id}/${encFile.name}`;

      const uploadResult = await uploadFile(encFile, pdfLocation);

      const base64 = await convertToBase64(pdfFile).then((data) => {
        return data;
      });

      console.log("pdfFileInside", pdfFile);

      senderBlob.originalPdf = uploadResult.location;
      senderBlob.title = docInfo.title;
      senderBlob.desc = docInfo.desc;
      senderBlob.pdfOriginalImagesArray = pdfFile;
      senderBlob.arrayBuffer = base64;

      const senderBlobAsString = flatted.stringify(senderBlob);

      senderMetadata.blobHash = EDH.SHA1_hash(senderBlobAsString);

      const cipherText = CryptoJS.AES.encrypt(
        senderBlobAsString,
        docInfo.password
      ).toString();
      senderMetadata.blob = cipherText;

      const senderMetadataAsJsonFile = EDH.objectToFile(senderMetadata);
      console.log(senderMetadataAsJsonFile);

      const senderFileResult = await uploadFile(
        senderMetadataAsJsonFile,
        `documentSigning/metadata/${userLocal.id}/${senderMetadataAsJsonFile.name}`
      );

      const receiversMetadataArray = await prepareReceiverMetadata(
        pdfMetadata.signers,
        metadata,
        docInfo.password,
        senderBlob
      );
      // console.log(receiversMetadataArray);

      const totalSigners = pdfMetadata.signers.length;
      const totalSigned = 0;

      const signers = [];
      receiversMetadataArray.forEach((element) => {
        signers.push({
          name: element.signer.name,
          email: element.signer.email.toLowerCase(),
          metadataUrl: element.url,
        });
      });
      let body = {
        ...docInfo,
        totalSigners,
        totalSigned,
        recruiter_id: userLocal?.recruiter_id,
        workspace_id: userLocal?.workspace_id,
        metadataUrl: senderFileResult.location,
        signers: signers,
        userName: userLocal.name,
      };
      if (props?.isEdit) {
        body = { ...body, uuid: props?.uuid, isDraft: 0 };
      }
      const result = await storeSigningService(body);
      console.log(result);
      if (result.status === 200) {
        setProcessSuccess("Successfuly Email sent to signers");
        dispatch(ShowAlert(result?.data?.message, "success"));
        setTimeout(() => {
          setSuccessOpenModel(false);
          setProcessSuccess(null);
          // setMessage("Created Success");
          navigation(routes.ALL_DOCUMENTS);
          dispatch(setUploadPdf(null));
          dispatch(setSigningMetadata(null));
        }, 1000);
      } else {
        setProcessSuccess("Failed, please try again");
        dispatch(ShowAlert(result?.data?.message, "error"));
        setTimeout(() => {
          setSuccessOpenModel(false);
          setProcessSuccess(null);
        }, 1000);
      }
    } catch (error) {
      setProcessSuccess("Failed, please try again");
      setTimeout(() => {
        setSuccessOpenModel(false);
        setProcessSuccess(null);
      }, 1000);
      console.error("submit error", error);
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const prepareReceiverMetadata = async (
    signers,
    baseMetadata,
    originalEncryptionKey,
    senderBlob
  ) => {
    const list = [];
    console.log("signerssssss", signers);
    for (const signer of signers) {
      const blob = {
        ...senderBlob,
      };

      const isSender =
        signer.email.toLowerCase() === userLocal.email.toLowerCase();
      const metadata = {
        ...baseMetadata,
        isSender: isSender,
      };

      blob.signers = [signer];
      console.log(blob.signers);
      const signerBlobAsString = flatted.stringify(blob);

      metadata.blobHash = EDH.SHA1_hash(signerBlobAsString);

      const cipherText = CryptoJS.AES.encrypt(
        signerBlobAsString,
        originalEncryptionKey
      ).toString();
      metadata.blob = cipherText;
      const signerMetadataAsJsonFile = EDH.objectToFile(metadata);
      console.log(signerMetadataAsJsonFile);

      const signerFileResult = await uploadFile(
        signerMetadataAsJsonFile,
        `documentSigning/metadata/${userLocal.id}/${signerMetadataAsJsonFile?.name}`
      );
      list.push({ signer, url: signerFileResult.location });
    }

    return list;
  };

  const onDocCheckedChange = (e) => {
    setDocInfo({ ...docInfo, [e.target.name]: e.target.checked });
  };

  return (
    <div className="dashboard_left left_column">
      <div className={style.dashboard_left_inner}>
        <div className={`${style.pdf_row}`}>
          <h6>Signers</h6>
          <Form.Select onChange={props.handleSignerChange} size="lg">
            {props.sinersList.map((siner) => {
              return (
                <option key={siner.id} value={siner.email}>
                  {" "}
                  {siner.me ? "You" : siner.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div
          className={`${style.pdf_row}`}
          draggable="true"
          onDragEnd={(x) => props.onDragEnd({ x })}
        >
          <img
            alt="logo"
            className="App-logo"
            src={TextInput}
            style={{ pointerEvents: "none" }}
          />
          <span>{FieldTypes.TEXT_INPUT}</span>
        </div>
        <div
          className={`${style.pdf_row}`}
          draggable
          onDragEnd={(x) => props.onDragEnd({ x })}
        >
          <img
            alt="logo"
            className="App-logo"
            src={Calender}
            style={{ pointerEvents: "none" }}
          />
          <span>{FieldTypes.DATES}</span>
        </div>

        <div
          className={`${style.pdf_row}`}
          draggable
          onDragEnd={(x) => props.onDragEnd({ x })}
        >
          <img
            alt="logo"
            className="App-logo"
            src={CheckBoxMenu}
            style={{ pointerEvents: "none" }}
            title="CheckBox"
          />
          <span>{FieldTypes.CHECKBOX}</span>
        </div>

        <div
          className={`${style.pdf_row}`}
          draggable
          onDragEnd={(x) => props.onDragEnd({ x })}
        >
          <img
            alt="logo"
            className="App-logo"
            src={Initials}
            style={{ pointerEvents: "none" }}
          />
          <span>{FieldTypes.INITIALS}</span>
        </div>
        <div
          className={`${style.pdf_row}`}
          draggable
          onDragEnd={(x) => props.onDragEnd({ x })}
        >
          <img
            alt="logo"
            className="App-logo"
            src={Signature}
            style={{ pointerEvents: "none" }}
          />
          <span>{FieldTypes.SIGNATURE}</span>
        </div>
        <div className="bottom_border">
          <div className={`${style.inner_border_box}`}>
            <h6>How it works ?</h6>
            <p>
              Step 1. Place nominated input onto the document. This field can be
              dragged and dropped anywhere on the form.
            </p>
            <br />
            <p>
              Step 2. Once completed click "Send" to deliver your document to
              the nominated email address of the signer.
            </p>
          </div>
        </div>
      </div>

      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className="optionContainer iconPopups">
          <div className="margin_box popup">
            <form>
              <div className="form-group">
                <label htmlFor="forDoctitle">Document title*</label>
                <input
                  aria-describedby="forDoctitle"
                  autoComplete="off"
                  className="form-control text-dark"
                  id="forDoctitle"
                  name="title"
                  onChange={onDocInfoChange}
                  placeholder="Enter document title"
                  type="text"
                />
                <small className="form-text text-danger" id="emailHelp">
                  {errors && errors.title && <>{errors.title}</>}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Description</label>
                <textarea
                  className="form-control text-dark"
                  id="exampleFormControlTextarea1"
                  name="desc"
                  onChange={onDocInfoChange}
                  placeholder="Description"
                  rows={3}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  Create document password*
                </label>
                <input
                  className="form-control text-dark"
                  name="password"
                  onChange={onDocInfoChange}
                  placeholder="Create Document password"
                  type="password"
                />
                <small className="form-text text-danger" id="emailHelp">
                  {errors && errors.password && <>{errors.password}</>}
                </small>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputcnfPassword1">
                  Create document confirm password*
                </label>
                <input
                  autoComplete="new-password"
                  className="form-control text-dark"
                  name="cnfPassword"
                  onChange={onDocInfoChange}
                  placeholder="Create Document confirm password"
                  type="password"
                />
                <small className="form-text text-danger" id="emailHelp">
                  {errors && errors.cnfPassword && <>{errors.cnfPassword}</>}
                </small>
              </div>
            </form>

            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  name="emailPasswordToMe"
                  onChange={onDocCheckedChange}
                  style={{ margin: 0, left: "15px" }}
                  type="checkbox"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  style={{ paddingLeft: "20px" }}
                >
                  Email password to me.
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  id="flexCheckChecked"
                  name="emailPasswordToSigners"
                  onChange={onDocCheckedChange}
                  style={{ margin: 0, left: "15px" }}
                  type="checkbox"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckChecked"
                  style={{ paddingLeft: "20px" }}
                >
                  Email password to all Signers.
                </label>
              </div>
            </div>
          </div>
          <div className="button_box">
            <button onClick={handleMetadataSubmit} type="button">
              Send
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {}}
        open={successOpenModel}
        showCloseIcon={false}
      >
        <div
          className="optionContainer iconPopups"
          style={{ minWidth: 200, paddingTop: 30 }}
        >
          <div className="margin_box popup text-center">
            {/* <img
              src={LoaderGif}
              style={{ margin: "auto", width: 50, height: 50 }}
            /> */}
            {singleCandidate?.loading && (
              <Box className="loader">
                <CircularProgress />
              </Box>
            )}
            {processSuccessful ? processSuccessful : "Processing..."}
          </div>
          <div className="button_box"></div>
        </div>
      </Modal>
    </div>
  );
});

export default AssignFieldsLeftColumn;
