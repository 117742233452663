import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import LoadingBar from 'react-top-loading-bar';

import "../../assets/sinner_css/assignFields.css";
import {
  setPdfImagesArray,
  setOriginalPdfArray,
  setSigners,
  setUploadPdf,
} from "../../store/actions/pdfupload.actions";
import { setSigningMetadata } from "../../store/actions/signingMetadata.actions";
import {
  FieldTypes,
  FontList,
  FontSize,
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../store/constants/constants";
import AssignFieldRightMenu from "../../components/common/sinner/AssignFieldRightMenu.jsx";
import AssignFieldsLeftColumn from "../../components/common/sinner/AssignFieldsLeftColumn.jsx";
// import DashboardHeader from '../common/DashboardHeader';
import DropCheckbox from "../../components/common/sinner/DropCheckBox.jsx";
import DropDate from "../../components/common/sinner/DropDate.jsx";
import DropInitials from "../../components/common/sinner/DropInitials.jsx";
import DropInput from "../../components/common/sinner/DropInput.jsx";
import DropSignature from "../../components/common/sinner/DropSignature.jsx";
import style from "../../components/document_sinner/styles.module.scss";
import PopupModal from "../../components/document_sinner/PopupModal.jsx";
import { downloadFile, uploadFile } from "../../utils/s3Config.js";
import {
  EDocTemplateDetails,
  getDraftById,
  getFormCategoryDocBuilder,
  storeSigningService,
  storeTemplate,
  updatedDraft,
  updateTemplate,
} from "../../api/sinner/SinnerApi.js";
import { ShowAlert } from "../../store/actions/alertActions.js";
import { routes } from "../../routes.js";
import { Box, CircularProgress } from "@mui/material";
import Modal from "react-responsive-modal";
import axios from "axios";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js";
/****
 * this page component add new fields to the metadata.
 */

function AssignFields() {
  const pdfFile = useSelector((state) => state.pdfUploadReducer).pdfFile;

  const navigation = useNavigate();
  const sinersList = useSelector((state) => state.pdfUploadReducer).signersList;
  const [selectedSigner, setSelectedSigner] = useState(sinersList[0]);
  console.log("sinersList", sinersList);
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  });
  const canvasContainerRef = useRef();
  const pdfBgRef = useRef();
  const ref = useRef();
  const childRef = useRef();
  const { state } = useLocation();
  let pdfMetadata = useSelector((s) => s.signingMetadataReducer);
  const singleCandidate = useSelector((state) => state?.singleCandidate);

  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};
  console.log("pdfMetadata", pdfMetadata);

  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const dispatch = useDispatch();
  const pdfImagesList = useSelector(
    (state) => state.pdfUploadReducer
  ).pdfImagesList;

  const [selectedInputField, setSelectedInputField] = useState(null);
  const [heightOfPage, setHeightOfPage] = useState(0);
  const [documentImages, setDocumentImages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [draftForm, setDraftForm] = useState({
    title: "",
    desc: "",
  });
  const [draftEditForm, setDraftEditForm] = useState({
    title: "",
    desc: "",
  });
  const [openDraft, setOpenDraft] = useState(false);
  const [errors, setErrors] = useState(null);
  const [pdfMetaUrl, setpdfMetaUrl] = useState("");
  const [deletedSinnerDraft, setDeletedSinnerDraft] = useState([]);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedSigner(sinersList[0]);
  }, [sinersList]);

  useEffect(() => {
    dispatch(setPdfImagesArray([]));
    dispatch(setOriginalPdfArray([]));

    if (!state?.uuid && !state?.templateUuid) {
      if (pdfFile === null) {
        navigation(-1);
        return;
      }
      loadPdf({});
    } else if (state?.uuid) {
      if (!pdfMetadata) {
        navigation(-1);
      } else {
        handelGetDraftedDocument();
      }
    } else if (state?.templateUuid) {
      if (sinersList?.length <= 0) {
        navigate(-1);
      }
      handelTemplateDetails();
    }
    window.addEventListener("beforeunload", handleEndConcert);
    return () => {
      //window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener("beforeunload", handleEndConcert);
    };
  }, []);

  const handelGetDraftedDocument = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      const response = await getDraftById(state?.uuid);

      if (response?.data?.code === 200) {
        const data = response?.data?.results;
        console.log("testttt", data);

        setDraftEditForm({
          title: data?.title,
          desc: data?.desc,
        });
        const pdfUrl = downloadFile(data?.metadataUrl);
        setpdfMetaUrl(data?.metadataUrl);
        pdfMetadata.signers = data?.signers;

        for (let i = 0; i < data?.signers?.length; i++) {
          if (data?.signers[i].signerEmail === userData?.email) {
            data.signers[i].me = "me";
          }
          console.log("ssssssss", data.signers);
          data.signers[i].email = data.signers[i].signerEmail;
          data.signers[i].DbId = data.signers[i].id;
          data.signers[i].isDeleted = 0;
          delete data.signers[i].signerEmail;
        }

        loadPdf({ pdfUrl: pdfUrl, signers: data?.signers });
        dispatch(setSigners(data?.signers));
        setSelectedSigner(data?.signers[0]);
        setDeletedSinnerDraft([]);
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
    } catch (error) {
      console.error("errorsssss", error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const handleClick = () => {
    if (childRef.current) {
      childRef.current.sendDocs();
    }
  };

  const handleEndConcert = (e) => {
    e.preventDefault();
    e.returnValue = "Are you sure you want to leave this page?";
  };

  const handleSignerChange = (e) => {
    sinersList.forEach((element) => {
      if (element.email.toLowerCase() === e.target.value.toLowerCase()) {
        setSelectedSigner(element);
        return;
      }
    });
  };

  const onDragEnd = ({ x = null, selectedSigners = null }) => {
    const fieldType = x?.target?.outerText || {};
    console.log("selectedSigner", selectedSigner);
    const canvasTopHeight =
      document.getElementsByClassName("header_box")[0].clientHeight +
      canvasContainerRef.current.offsetTop;
    console.log(canvasTopHeight);

    const signerTemp = selectedSigner || selectedSigners;
    let posFromTop;
    let posfromleft;
    if (x) {
      posFromTop = x.pageY - canvasTopHeight;
      posfromleft = x.pageX - canvasContainerRef.current.offsetLeft;
      posfromleft = posfromleft < 0 ? 0 : posfromleft;
    }
    console.log(selectedSigner, fieldType);
    // console.log(x.pageY - canvasTopHeight);
    if (selectedSigner) {
      switch (fieldType) {
        case FieldTypes.TEXT_INPUT:
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 100,
            background: selectedSigner?.color,
            height: "39px",
            isNew: true,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.TEXT_INPUT,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Input Text",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });
          break;
        case FieldTypes.DATES:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 100,
            height: "38px",
            isNew: true,
            background: selectedSigner.color,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.DATES,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Dates Picker",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;
        case FieldTypes.INITIALS:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 100,
            height: "40px",
            isNew: true,
            background: selectedSigner.color,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.INITIALS,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Initials",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;
        case FieldTypes.SIGNATURE:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 200,
            height: 50,
            background: selectedSigner.color,
            isNew: true,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.SIGNATURE,
            date: "",
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Signature",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;
        case FieldTypes.CHECKBOX:
          //const signerTemp = selectedSigner;
          selectedSigner.fields.push({
            id: new Date().getTime(),
            width: 40,
            height: 40,
            positionFromParentLeft: posfromleft,
            positionFromParentTop: posFromTop,
            fieldType: FieldTypes.CHECKBOX,
            isNew: true,
            date: "",
            background: selectedSigner.color,
            fontSize: FontSize[0].size,
            fontFamily: FontList[0].fontFamily,
            isRequired: true,
            signer: signerTemp,
            placeholder: "Check Box",
            page: 0,
            pagePositionY: 0,
            maxWidth: 0,
            pageWidth: 0,
          });

          break;

        default:
          break;
      }
    }
    console.log(pdfMetadata);
    dispatch(setSigningMetadata({ ...pdfMetadata }));
    console.log("selectedSignerFields", selectedSigner?.fields);
    if (selectedSigner?.fields?.length > 0) {
      const selected = selectedSigner.fields[selectedSigner.fields.length - 1];
      // console.log("selected", selected);
      setSelectedInputField(selected);
    }

    for (let i = 0; i < selectedSigner?.fields?.length; i++) {
      onStop(selectedSigner?.fields[i], selectedInputField);
    }
  };

  const loadPdf = async ({ pdfUrl = null, signers = null }) => {
    try {
      // ref.current.continuousStart();

      if (pdfUrl) {
        const response = await axios.get(pdfUrl, {
          responseType: "arraybuffer", // Important for handling binary data
        });

        const blob = new Blob([response.data], { type: "application/pdf" });

        const file = new File([blob], "sample.pdf", {
          lastModified: new Date().getTime(),
        });
        dispatch(setUploadPdf(file));
      }
      const url = pdfUrl ? pdfUrl : URL.createObjectURL(pdfFile);

      const pdf = await getDocument(url).promise;
      const arr = [];
      let totalHeight = 0;
      let totalWidth = 0;
      let pageHeight = 0;

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const scale = 1.0,
          viewport = page.getViewport({ scale }),
          canvas = document.createElement("canvas"),
          context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        pageHeight = viewport.height;

        totalHeight = totalHeight + canvas.height;
        totalWidth = canvas.width;

        const task = page.render({
          canvasContext: context,
          viewport: viewport,
        });

        await task.promise;
        arr.push({
          imgBase64: canvas.toDataURL(),
          height: canvas.height,
          width: canvas.width,
        });
      }
      console.log(arr);
      setDocumentImages(arr);
      dispatch(setPdfImagesArray([...arr]));
      dispatch(setOriginalPdfArray(pdfFile));
      setHeightOfPage(pageHeight);

      pdfMetadata.pdfImagesList = arr;
      pdfMetadata.containerSize = {
        width: totalWidth,
        height: totalHeight,
      };
      pdfMetadata.pageHeight = pageHeight;
      dispatch(setSigningMetadata({ ...pdfMetadata }));
      setContainerSize({
        width: totalWidth,
        height: totalHeight,
      });
      setIsPdfLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setContainerSize({
        width: pdfBgRef?.current.clientWidth,
        height: pdfBgRef?.current.clientHeight,
      });
    }, 0);
  }, [isPdfLoaded]);

  useEffect(() => {
    if (!openDraft && !openTemplate) {
      setErrors({});
      setDraftForm({
        title: "",
        desc: "",
      });
    } else if (openDraft && state?.uuid) {
      setDraftForm(draftEditForm);
    } else if (openTemplate) {
      getCategoryList();
      if (state?.templateUuid) {
        setDraftForm(draftEditForm);
      }
    }
  }, [draftEditForm, openDraft, state?.uuid, openTemplate]);

  const handleScroll = (event) => {
    setOffset(event.currentTarget.scrollTop);
  };

  const onResize = () => {};
  const onElementDelete = (elt, signer) => {
    setSelectedInputField(null);
    const temp = signer.fields.filter((e) => elt.id !== e.id);
    signer.fields = [...temp];
    dispatch(setSigningMetadata({ ...pdfMetadata }));
  };

  const onStop = (x, selectedInputField) => {
    let pageNumber = 0;
    console.log("selectedSigner?.fields", x);
    let totalHeightofDoc = 0;
    let maxWidth = 0;

    for (let j = 0; j < documentImages.length; j++) {
      if (maxWidth < documentImages[j].width) {
        maxWidth = documentImages[j].width;
      }
    }

    for (let i = 0; i < documentImages.length; i++) {
      const element = documentImages[i];
      const elementHeight = element.height + 1;
      totalHeightofDoc += elementHeight;
      pageNumber++;

      if (x.positionFromParentTop < totalHeightofDoc) {
        break;
      } else {
        continue;
      }
    }
    console.log("documentImages", documentImages, pageNumber);
    x.page = pageNumber;
    x.pagePositionY = totalHeightofDoc - x.positionFromParentTop;
    x.maxWidth = maxWidth;
    x.pageWidth = documentImages[pageNumber - 1].width;
    console.log("insidexx", x);
    setSelectedInputField(x);
  };
  const onFieldItemClick = (elt, signer) => {};

  const handelChange = (e) => {
    setErrors({});
    const { name, value } = e.target || {};
    setDraftForm((preVal) => {
      if (name === "title") {
        preVal = {
          ...preVal,
          [name]: preVal?.title?.length === 0 ? value.trim() : value,
        };
      } else {
        preVal = { ...preVal, [name]: value };
      }

      return { ...preVal };
    });
  };

  const handelSaveDraft = async () => {
    const error = {};
    if (!draftForm?.title) {
      error.title = "Please fill the field";
    } else if (draftForm?.title?.length > 64) {
      error.title = "Title should be maximum 64 characters";
    }
    setErrors({ ...error });
    if (error?.title) {
      return;
    }

    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let uploadFileResponse;
      if (!state?.uuid) {
        const pdfLocation = `media/documentSigning/pdf/draft/${
          userData.id
        }/${new Date().getTime()}`;
        uploadFileResponse = await uploadFile(pdfFile, pdfLocation);
      }
      for (let i = 0; i < sinersList?.length; i++) {
        for (let j = 0; j < sinersList[i]?.fields?.length; j++) {
          delete sinersList[i]?.fields[j].signer;
        }
      }
      let deletedSignersInDraft;
      if (state?.uuid) {
        deletedSignersInDraft = [...deletedSinnerDraft];

        for (let i = 0; i < deletedSignersInDraft?.length; i++) {
          for (let j = 0; j < deletedSignersInDraft[i]?.fields?.length; j++) {
            delete deletedSignersInDraft[i]?.fields[j].signer;
          }
        }
      }

      console.log("sinnerList", sinersList?.length);
      let body = {
        recruiter_id: userData?.recruiter_id,
        workspace_id: userData?.workspace_id,
        metadataUrl: uploadFileResponse?.location,
        // Spread the FormData properties
        ...draftForm,
        isDraft: 1,
        totalSigners: sinersList?.length,
        totalSigned: 0,
        // Pass the sinersList array directly
        signers: sinersList,
        userName: userData.name,
      };
      if (state?.uuid) {
        body = {
          ...body,
          uuid: state.uuid,
          metadataUrl: pdfMetaUrl,
          deletedSigner: deletedSignersInDraft,
        };
      }

      for (let i = 0; i < body?.signers?.length; i++) {
        for (let j = 0; j < body?.signers?.[i]?.fields?.length; j++) {
          body.signers[i].fields[j].isNew = false;
        }
      }
      let result;
      if (state?.uuid) {
        result = await updatedDraft(body);
      } else {
        result = await storeSigningService(body);
      }
      console.log(result);
      if (result?.data?.code === 200) {
        dispatch(ShowAlert(result?.data?.message, "success"));
        navigate(routes.ALL_DOCUMENTS);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };
  const handelSaveTemplate = async (sinersList) => {
    let error = {};

    if (!draftForm?.title) {
      error.title = "Please fill the filed";
    } else if (draftForm?.title?.length > 64) {
      error.title = "Title should be maximum 64 characters";
    }
    if (!draftForm?.category) {
      error.category = "Please fill the filed";
    }
    setErrors({ ...error });
    if (error?.title || error?.category) {
      return;
    }
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let uploadFileResponse;
      if (!state?.templateUuid) {
        const pdfLocation = `media/documentSigning/pdf/template/${
          userData.id
        }/${new Date().getTime()}`;
        uploadFileResponse = await uploadFile(pdfFile, pdfLocation);
      }
      for (let i = 0; i < sinersList?.length; i++) {
        for (let j = 0; j < sinersList[i]?.fields?.length; j++) {
          delete sinersList[i]?.fields[j].signer;
        }
      }
      let signers = JSON.parse(JSON.stringify(sinersList));
      for (let i = 0; i < signers?.length; i++) {
        for (const key in signers[i]) {
          if (key !== "fields") {
            signers[i][key] = "";
          }
        }
      }

      let body = {
        recruiter_id: userData?.recruiter_id,
        workspace_id: userData?.workspace_id,
        metadataUrl: uploadFileResponse?.location,
        // Spread the FormData properties
        ...draftForm,

        totalSigners: signers?.length,
        totalSigned: 0,
        // Pass the signers array directly
        fields: signers,
        userName: userData.name,
      };
      let deletedSignersInDraft;
      if (state?.uuid) {
        deletedSignersInDraft = [...deletedSinnerDraft];

        for (let i = 0; i < deletedSignersInDraft?.length; i++) {
          for (let j = 0; j < deletedSignersInDraft[i]?.fields?.length; j++) {
            delete deletedSignersInDraft[i]?.fields[j].signer;
          }
        }
      }
      if (state?.templateUuid) {
        body = {
          ...body,
          uuid: state.templateUuid,
          metadataUrl: pdfMetaUrl,
          deletedSigner: deletedSignersInDraft,
        };
      }
      for (let i = 0; i < body?.fields?.length; i++) {
        for (let j = 0; j < body?.fields?.[i]?.fields?.length; j++) {
          body.fields[i].fields[j].isNew = false;
        }
      }
      console.log("bodyss", body);

      let result;
      if (state?.templateUuid) {
        result = await updateTemplate(body);
      } else {
        result = await storeTemplate(body);
      }

      if (result?.data?.code === 200) {
        dispatch(ShowAlert(result?.data?.message, "success"));
        navigate(routes.ALL_DOCUMENTS);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const getCategoryList = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let body = {
        isDocBuilder: 1,
      };
      const result = await getFormCategoryDocBuilder(body);
      if (result?.data?.code === 200) {
        setCategoryList(result?.data?.results || []);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };

  const handelTemplateDetails = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      const response = await EDocTemplateDetails(state?.templateUuid);

      if (response?.data?.code === 200) {
        const data = response?.data?.results;

        setDraftEditForm({
          title: data?.title,
          desc: data?.desc,
          category: data?.category,
        });
        console.log("dataaaaaa", data?.title);
        const pdfUrl = downloadFile(data?.metadataUrl);
        setpdfMetaUrl(data?.metadataUrl);
        setDeletedSinnerDraft([]);
        loadPdf({ pdfUrl: pdfUrl });
        for (let i = 0; i < sinersList?.length; i++) {
          if (sinersList[i].signerEmail === userData?.email) {
            sinersList[i].me = "me";
          }

          sinersList[i].DbId = sinersList[i].id;
          sinersList[i].isDeleted = 0;
        }
        for (let i = 0; i < sinersList?.length; i++) {
          for (let j = 0; j < sinersList[i]?.fields?.length; j++) {
            sinersList[i].fields[j].signer = sinersList[i];
          }
        }
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
    } catch (error) {
      console.error("errorsssss", error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };
  console.log("selectedInputField", selectedInputField);
  return (
    <>
      <div className="header_box"></div>
      {/* <DashboardHeader /> */}
      {/* <LoadingBar color="#f11946" ref={ref} /> */}

      <div
        className={`${style.pdf_format}`}
        style={{ height: "calc(100vh - 255px)" }}
      >
        <AssignFieldsLeftColumn
          handleSignerChange={handleSignerChange}
          onDragEnd={onDragEnd}
          ref={childRef}
          sinersList={sinersList}
          isEdit={state?.uuid ? true : false}
          uuid={state?.uuid}
        />
        <div
          className="dashboard_right right_column"
          id="scrollableaxis"
          onScroll={handleScroll}
        >
          <div
            className={`${style.innerBox}`}
            style={{
              height: "fit-content",
              overflowY: "hidden",
            }}
          >
            {pdfImagesList.length === 0 && <p>Loading ...</p>}
            <div
              className="pdfWrapper"
              id="pdfScroll"
              style={{
                border: "1px solid",
                width: "fit-content",
                margin: "auto",
              }}
            >
              <div
                className="canvas"
                ref={canvasContainerRef}
                style={{
                  position: "relative",
                  height: containerSize.height,
                  width: containerSize.width,
                }}
              >
                <div className="pdfBg" ref={pdfBgRef}>
                  {pdfImagesList?.length > 0 &&
                    pdfImagesList?.map((m, i) => (
                      <img
                        alt="pdf"
                        key={i}
                        src={m.imgBase64}
                        style={{
                          pointerEvents: "none",
                          borderBottom: "solid 1px",
                          maxWidth: "none",
                        }}
                      ></img>
                    ))}
                </div>

                {pdfMetadata?.signers?.map((signer) => {
                  return signer?.fields?.map((elt, i) => {
                    let FieldComponent = null;
                    switch (elt.fieldType) {
                      case FieldTypes.TEXT_INPUT:
                        FieldComponent = DropInput;
                        break;
                      case FieldTypes.DATES:
                        FieldComponent = DropDate;
                        break;
                      case FieldTypes.INITIALS:
                        FieldComponent = DropInitials;
                        break;
                      case FieldTypes.SIGNATURE:
                        FieldComponent = DropSignature;
                        break;
                      case FieldTypes.CHECKBOX:
                        FieldComponent = DropCheckbox;
                        break;
                      default:
                        <></>;
                    }

                    console.log("selectedInputField", elt);

                    return (
                      <FieldComponent
                        defaultPosition={{
                          x: elt.positionFromParentLeft,
                          y: elt?.isNew ? offset : elt.positionFromParentTop,
                        }}
                        elt={elt}
                        id={elt.id}
                        key={elt.id}
                        onElementDelete={onElementDelete}
                        onFieldItemClick={onFieldItemClick}
                        onResize={onResize}
                        onStop={onStop}
                        selectedInputField={selectedInputField}
                        setSelectedInputField={setSelectedInputField}
                        signer={signer}
                      />
                    );
                  });
                })}
              </div>
            </div>
          </div>
        </div>
        <AssignFieldRightMenu
          selectedInputField={selectedInputField}
          sendData={handleClick}
          setSelectedInputField={setSelectedInputField}
          setOpenDraft={setOpenDraft}
          isEdit={state?.uuid ? true : false}
          setDeletedSinnerDraft={setDeletedSinnerDraft}
          setOpenTemplate={setOpenTemplate}
        />

        <PopupModal
          closeOnEsc={false}
          closeOnOverlayClick={false}
          onClose={() => setOpenDraft(false)}
          open={openDraft}
          showCloseIcon={true}
        >
          <div className="optionContainer iconPopups">
            <div className="margin_box popup">
              <form>
                <div className="form-group">
                  <label htmlFor="forDoctitle">Document title*</label>
                  <input
                    aria-describedby="forDoctitle"
                    autoComplete="off"
                    className="form-control text-dark"
                    id="forDoctitle"
                    name="title"
                    onChange={handelChange}
                    placeholder="Enter document title"
                    value={draftForm?.title}
                    type="text"
                  />
                  <small className="form-text text-danger" id="emailHelp">
                    {errors && errors.title && <>{errors.title}</>}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Description
                  </label>
                  <textarea
                    className="form-control text-dark"
                    id="exampleFormControlTextarea1"
                    name="desc"
                    onChange={handelChange}
                    placeholder="Description"
                    value={draftForm?.desc}
                    rows={3}
                  />
                </div>
              </form>
            </div>
            <div className="button_box">
              <button onClick={handelSaveDraft} type="button">
                {state?.uuid ? "Upadte" : "Save"}
              </button>
            </div>
          </div>
        </PopupModal>
        {/* template starts */}
        <PopupModal
          closeOnEsc={false}
          closeOnOverlayClick={false}
          onClose={() => setOpenTemplate(false)}
          open={openTemplate}
          showCloseIcon={true}
        >
          <div className="optionContainer iconPopups">
            <div className="margin_box popup">
              <form>
                <div className="form-group">
                  <label htmlFor="forDoctitle">Template title*</label>
                  <input
                    aria-describedby="forDoctitle"
                    autoComplete="off"
                    className="form-control text-dark"
                    id="forDoctitle"
                    name="title"
                    onChange={handelChange}
                    placeholder="Enter document title"
                    value={draftForm?.title}
                    type="text"
                  />
                  <small className="form-text text-danger" id="emailHelp">
                    {errors && errors.title && <>{errors.title}</>}
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Description
                  </label>
                  <textarea
                    className="form-control text-dark"
                    id="exampleFormControlTextarea1"
                    name="desc"
                    onChange={handelChange}
                    placeholder="Description"
                    value={draftForm?.desc}
                    rows={3}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Template Category*
                  </label>
                  <select
                    name="category"
                    id=""
                    onChange={handelChange}
                    className="form-control text-dark"
                  >
                    <option
                      style={{ display: "hidden" }}
                      value=""
                      disabled
                      selected={
                        state?.templateUuid && draftForm?.category
                          ? false
                          : true
                      }
                    >
                      Select category
                    </option>
                    {categoryList?.length > 0 &&
                      categoryList?.map((item) => {
                        return (
                          <option
                            value={item?.id}
                            key={item?.id}
                            selected={
                              state?.templateUuid
                                ? draftForm?.category === item?.id
                                : false
                            }
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                  </select>
                  <small className="form-text text-danger" id="emailHelp">
                    {errors && errors.category && <>{errors.category}</>}
                  </small>
                </div>
              </form>
            </div>
            <div className="button_box">
              <button
                onClick={() => handelSaveTemplate(sinersList)}
                type="button"
              >
                {state?.templateUuid ? "Upadte" : "Save"}
              </button>
            </div>
          </div>
        </PopupModal>
        {/* template ends */}
      </div>
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {}}
        open={singleCandidate?.loading}
        showCloseIcon={false}
      >
        <div
          className="optionContainer iconPopups"
          style={{ minWidth: 200, paddingTop: 30 }}
        >
          <div className="margin_box popup text-center">
            {/* <img
              src={LoaderGif}
              style={{ margin: "auto", width: 50, height: 50 }}
            /> */}
            {singleCandidate?.loading && (
              <Box className="loader">
                <CircularProgress />
              </Box>
            )}
          </div>
          <div className="button_box"></div>
        </div>
      </Modal>
    </>
  );
}
export default AssignFields;
