// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_customBox__7YlOW table {\n  box-shadow: none;\n}\n.styles_customBox__7YlOW .styles_contentBox__pbMbg td {\n  font-style: normal;\n  font-size: 16px;\n  line-height: 24px;\n  box-shadow: none;\n  word-break: break-all;\n  /* identical to box height, or 200% */\n  color: #6c6a6a;\n}\n.styles_customBox__7YlOW .styles_headingBox__jNuOW th {\n  font-family: \"poppins-semi-bold\";\n  font-style: normal;\n  font-size: 16px;\n  line-height: 28px;\n  /* identical to box height, or 215% */\n  letter-spacing: 0.2px;\n  text-transform: capitalize;\n  color: #92929d;\n}", "",{"version":3,"sources":["webpack://./src/components/dashboard/tabel/styles.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGI;EAEE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,qCAAA;EAEA,cAAA;AAHN;AAOI;EACE,gCAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,qCAAA;EAEA,qBAAA;EACA,0BAAA;EAEA,cAAA;AAPN","sourcesContent":[".customBox {\n  table {\n    box-shadow: none;\n  }\n  .contentBox {\n    td {\n      // font-family: \"poppins-semi-bold\";\n      font-style: normal;\n      font-size: 16px;\n      line-height: 24px;\n      box-shadow: none;\n      word-break: break-all;\n      /* identical to box height, or 200% */\n\n      color: #6c6a6a;\n    }\n  }\n  .headingBox {\n    th {\n      font-family: \"poppins-semi-bold\";\n      font-style: normal;\n      font-size: 16px;\n      line-height: 28px;\n      /* identical to box height, or 215% */\n\n      letter-spacing: 0.2px;\n      text-transform: capitalize;\n\n      color: #92929d;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customBox": "styles_customBox__7YlOW",
	"contentBox": "styles_contentBox__pbMbg",
	"headingBox": "styles_headingBox__jNuOW"
};
export default ___CSS_LOADER_EXPORT___;
