import { DELETE_CHECKBOX_OPTION } from "../constants/constants";
import { v4 as uuid } from "uuid";
import $ from "jquery";
export const Deletecheckbox = (index, getcheckboxlist) => {
  return {
    type: DELETE_CHECKBOX_OPTION,
    payload: getcheckboxlist,
    index: index,
  };
};
