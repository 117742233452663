// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_gridParrent__PU1as {\n  padding: 74px 62px;\n}\n@media screen and (max-width: 768px) {\n  .styles_gridParrent__PU1as {\n    padding: 30px 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/industry/singleIndustry/styles.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AAEE;EAHF;IAIA,kBAAA;EACE;AACF","sourcesContent":["@import \"../../../styles/theme/variables\";\n.gridParrent {\n  padding: 74px 62px;\n\n  @media screen and (max-width: 768px) {\npadding: 30px 16px;  \n}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridParrent": "styles_gridParrent__PU1as"
};
export default ___CSS_LOADER_EXPORT___;
