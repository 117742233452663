import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Cards from "react-credit-cards";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../../../../routes";
import { resetPricing } from "../../../../../../store/actions/pricingActions";
import { verifySingleCandidateCertificate } from "../../../../../../store/actions/singleCandidateScreenActions";
import TextInput from "../../../../../common/TextInput";
import styles from "./styles.module.scss";
import { candidateLicenseVerify } from "../../../../../../api/candidate/candidate.class";
import { ShowAlert } from "../../../../../../store/actions/alertActions";

const Checkout = ({
  setCart,
  setOpen,
  setLicenseValue,
  setLicense,
  setRadio,
  setBilling,
  setUpdateValues,
  data,
}) => {
  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const candidate = useSelector((state) => state?.rcandidate);
  const [Customerror, setErrors] = useState({});

  const initialState = {
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  };
  const CARD_VALIDATION =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;
  const GET_MONTH = new Date().getMonth();
  const GET_YEAR = new Date().getFullYear().toString().substr(-2);

  const navigate = useNavigate();

  const checkAlpha = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };

  const checkNumber = (value) => {
    return /^[0-9]*$/.test(value);
  };
  const onChange = (e) => {
    const { name, value } = e?.target;

    if (name === "name") {
      if ((checkAlpha(value) && value?.trim().length > 0) || value == "") {
        setValues({
          ...values,
          [name]: value,
        });
      }
    } else if (name === "number") {
      if (checkNumber(value)) {
        setValues({
          ...values,
          [name]: value,
        });
      }
    } else if (name === "cvc") {
      if (checkNumber(value) && value.length < 4) {
        setValues({
          ...values,
          [name]: value,
        });
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }
  };
  const [values, setValues] = useState(initialState);
  const onCheckedChange = (e) => {
    if (e?.target.checked) {
      setValues({ ...values, [e.target.name]: 1 });
      setErrors(false);
    } else {
      setValues({ ...values, [e?.target.name]: 0 });
      setErrors(true);
    }
  };
  const validate = (data) => {
    // console.log("validateData", data);
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "cvc":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          break;
        case "expiry":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          else if (
            Number(GET_YEAR) > Number(value.split("/")[1]) ||
            (Number(GET_YEAR) === Number(value.split("/")[1]) &&
              Number(GET_MONTH) >= Number(value.split("/")[0]))
          )
            newError[key] = "Please Type a valid expiry date ";
          else if (Number(value.split("/")[0] > 12))
            newError[key] = "Please Type a valid Month";
          else if (Number(value.split("/")[0].length === 0))
            newError[key] = "Please Type x Month";
          break;
        case "cvc":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          break;
        case "number":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          else if (value.length != 16 || isNaN(value)) {
            newError[key] = "Please enter 16 numbers";
          } else if (CARD_VALIDATION.test(value) === false)
            newError[key] = "Card format is incorrect";
          break;
        case "name":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          break;
        case "terms":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          break;
        default:
          return;
      }
    }
    return newError;
  };

  // console.log("data", data);

  const submitData = async (e) => {
    e.preventDefault();

    const validateSelect = validate(values);
    if (Object?.keys(validateSelect)?.length > 0) {
      setErrors(validateSelect);
      return;
    }
    const local_data = {
      // ...data,
      name: values?.name,
      card_number: values?.number.trim(),
      CVC: values?.cvc,
      exp_year: values?.expiry.split("/")[1],
      exp_month: values?.expiry.slice(0, 2),
      uuid: data?.uuid,
      terms: values?.terms,
      plan_name: values?.name,
    };
    // dispatch(candidateLicenseVerify(local_data)).then(() => {
    //   setOpen(false);
    // });
    const response = await candidateLicenseVerify(local_data);
    if (response?.data?.success === true) {
      dispatch(
        ShowAlert(
          "We will contact to third party organization on your behalf to verify this license.",
          "success"
        )
      );
      setOpen(false);
    } else {
      if (typeof response?.data?.message === "object") {
        Object.entries(response?.data?.message)?.map(([key, value], i) =>
          dispatch(ShowAlert(value, "error"))
        );
      } else {
        dispatch(ShowAlert(response?.data?.message, "error"));
      }
    }
  };
  useEffect(() => {
    if (payment?.paymentDone?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setCart({});
      setLicenseValue({});
      setLicense([]);
      setBilling({ address: "", city: "", state: "", country: "" });
      setRadio({});
    }
  }, [payment?.paymentDone]);

  useEffect(() => {
    if (payment?.updateSubscription?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setUpdateValues({
        label: "",
        value: "",
        interval_count: "",
        product: "",
        price: "00",
      });
    }
  }, [payment?.updateSubscription]);

  return (
    <Box className={styles.gridParrent}>
      <Typography component="h2">Check Summary</Typography>
      <table style={{ width: "100%", marginBottom: "20px" }}>
        <tbody>
          <tr>
            <th>Check Name</th>
            <td>{data?.industry_certification_license?.name}</td>
          </tr>
          <tr>
            <th>Check Price</th>
            <td>${data?.industry_certification_license?.price}</td>
          </tr>
        </tbody>
      </table>
      <Typography component="h2">Card Details</Typography>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item md={12} lg={6}>
          <form onSubmit={submitData}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12}>
                <Box className={styles.parentBox}>
                  <TextInput
                    compulsory={true}
                    customClass={styles.input}
                    label={"Name"}
                    placeholder={"name"}
                    name="name"
                    value={values?.name}
                    onChange={onChange}
                  />
                  {Customerror?.name && (
                    <p className="error-class position-absolute">
                      {Customerror?.name}
                    </p>
                  )}
                </Box>
              </Grid>
              <Grid item md={12} xs={12} lg={12}>
                <Box className={styles.parentBox}>
                  <TextInput
                    compulsory={true}
                    customClass={styles.input}
                    label={"Card Number"}
                    placeholder={"Card Number"}
                    name="number"
                    value={values?.number}
                    onKeyPress={(e) =>
                      values?.number?.length >= 16 ? e.preventDefault() : ""
                    }
                    onChange={onChange}
                  />
                  {Customerror?.number && (
                    <p className="error-class position-absolute">
                      {Customerror?.number}
                    </p>
                  )}
                </Box>
              </Grid>
              <Grid item md={6} xs={12} lg={6}>
                <Box className={styles.input3Parent}>
                  <label>
                    Expiry <span style={{ color: "red" }}>*</span>
                  </label>
                  <ReactInputMask
                    // mask={"$num"}

                    mask={"99/99"}
                    maskChar=" "
                    placeholder="MM/YY"
                    value={values?.expiry}
                    name="expiry"
                    className={styles.input3}
                    // className={styles.input}
                    onChange={onChange}
                  />
                  {Customerror?.expiry && (
                    <p className="error-class position-absolute">
                      {Customerror?.expiry}
                    </p>
                  )}
                </Box>
              </Grid>

              <Grid item md={6} xs={12} lg={6}>
                <Box className={styles.parentBox}>
                  <TextInput
                    compulsory={true}
                    customClass={styles.input}
                    label={"CVC"}
                    placeholder={"cvc"}
                    name="cvc"
                    value={values?.cvc}
                    onKeyPress={(e) =>
                      values?.cvc?.trim()?.length >= 4 ? e.preventDefault() : ""
                    }
                    onChange={onChange}
                  />
                  {Customerror?.cvc && (
                    <p className="error-class position-absolute">
                      {Customerror?.cvc}
                    </p>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={styles.customCheckBox}>
                  <label
                    className="labelContainer"
                    style={{ marginTop: "15px" }}
                  >
                    I have read and agree to the{" "}
                    <a
                      className="text-primary"
                      href={
                        data?.third_party_name === "vsure"
                          ? "https://www.vsure.com.au/terms-and-conditions-for-vsure/"
                          : "https://backycheck.com/terms-and-conditions.html"
                      }
                      target={"_blank"}
                    >
                      Terms of Service
                    </a>
                    <input
                      type="checkbox"
                      name="terms"
                      onChange={onCheckedChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </Box>
              </Grid>
            </Grid>
            <Box className={`button-primary ${styles.button}`}>
              <Button onClick={submitData} disabled={payment?.isLoading}>
                {payment?.isLoading ? (
                  <p style={{ color: "white" }}>Loading...</p>
                ) : (
                  "Send"
                )}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item md={12} lg={6} sx={{ widht: "100%" }}>
          <Cards
            cvc={values?.cvc}
            expiry={values?.expiry}
            focused={""}
            name={values?.name}
            number={values?.number}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
