// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_imageBox__juMHQ {\n  position: relative;\n  min-height: 100vh;\n  max-height: 100vh;\n}\n.styles_imageBox__juMHQ img {\n  position: absolute;\n  right: 0;\n  top: 0;\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/signup/ImagaSection/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,iBAAA;AACJ;AAAI;EACI,kBAAA;EACA,QAAA;EACA,MAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAER","sourcesContent":[".imageBox{\n    position: relative;\n    min-height: 100vh;\n    max-height: 100vh;\n    img{\n        position: absolute;\n        right: 0;\n        top: 0;\n        object-fit:cover;\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageBox": "styles_imageBox__juMHQ"
};
export default ___CSS_LOADER_EXPORT___;
