import { Grid, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import OptionTableRow from "./OptionTableRow";
import { useDispatch } from "react-redux";
import { Getcheckboxoptionlist } from "../../../../store/actions/getcheckboxoptionlist";
import { Getcheckboxoption } from "../../../../store/actions/getcheckboxoption";
import { Deletecheckbox } from "../../../../store/actions/deletecheckbox";
import { Deletecheckboxmain } from "../../../../store/actions/deletecheckboxmain";
import { useSelector } from "react-redux";
import Item from "./Item";
import style from "../style.module.scss";
import { storeFormStructure } from "../../../../store/actions/storeFormStructure";
import { FORM_STRUCTURE_UPDATE } from "../../../../store/constants/constants.js";

function OptionTable({ id, type }) {
  let dispatch = useDispatch();
  const getcheckboxlist = useSelector((state) => state?.getcheckboxlist);
  const getpreviewform = useSelector((state) => state?.getpreviewform);

  const [optioncount, Setoptioncount] = useState(2);
  const addTableRows = (val) => {
    // console.log("getpreviewform", getpreviewform);
    // console.log("id", id);
    const New = getpreviewform.map((item, count) => {
      const Addar = {
        label: "Option " + optioncount,
      };

      if (item.id === val) {
        Setoptioncount(optioncount + 1);
        return item.type.options.push(Addar);
      }
      return item;
    });

    dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, getpreviewform));
  };

  const deleteTableRows = () => {};

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <table className="table">
          <thead>
            <tr>
              <th>Option Label</th>
              <th>Option Value</th>
              <th>
                <button
                  className="btn btn-outline-success"
                  onClick={(e) => {
                    addTableRows(id);
                  }}
                >
                  +
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {getcheckboxlist.map((value) => {
              return value;
            })}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}

export default OptionTable;
