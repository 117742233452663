// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_parent__k8vwx h4 {\n  font-family: \"poppins-bold\";\n  font-style: normal;\n  font-size: 28px;\n  line-height: 24px;\n  /* identical to box height, or 75% */\n  color: #000000;\n}\n.styles_parent__k8vwx p {\n  max-width: 335px;\n  font-style: normal;\n  font-size: 16px;\n  line-height: 24px;\n  color: #909090;\n  margin: 30px 0 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/candidate_screens/jobs/application-form/styles.module.scss","webpack://./src/styles/theme/_variables.scss"],"names":[],"mappings":"AAEE;EACE,2BAAA;EACA,kBAAA;EAEA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,cCFS;ADAb;AAIE;EACE,gBAAA;EACA,kBAAA;EAEA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAHJ","sourcesContent":["@import \"../../../../styles/theme/variables\";\n.parent {\n  h4 {\n    font-family: \"poppins-bold\";\n    font-style: normal;\n\n    font-size: 28px;\n    line-height: 24px;\n    /* identical to box height, or 75% */\n    color: $black-color;\n  }\n  p {\n    max-width: 335px;\n    font-style: normal;\n\n    font-size: 16px;\n    line-height: 24px;\n    color: #909090;\n    margin: 30px 0 20px;\n  }\n}\n","\n\n\n$primary-color:#00CFC5;\n$primary-dark:#01A19A;\n$secondary-color:#05152E;\n$white-color:#FFFFFF;\n$black-color:#000000;\n$grey-color:#9A9696;\n$red-color:#FD8383;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": "styles_parent__k8vwx"
};
export default ___CSS_LOADER_EXPORT___;
