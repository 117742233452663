import { Grid, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import OptionTableRow from "./OptionTableRow";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import Item from "./Item";
import style from "../style.module.scss";
function OptionTableRadio({}) {
  let dispatch = useDispatch();

  const getcheckboxlist = useSelector((state) => state?.getcheckboxlist);

  const addTableRows = () => {};

  const deleteTableRows = () => {};

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <table className="table">
          <thead>
            <tr>
              <th>Radio Label</th>
              <th>Radio Value</th>
              <th>
                <button
                  className="btn btn-outline-success"
                  onClick={addTableRows}
                >
                  +
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {getcheckboxlist.map((value) => {
              return value;
            })}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}

export default OptionTableRadio;
