import EndPoints from "../EndPoints";
import axios from "../axiosConfig";
import axiosForm from "../uploadFile/axiosFormData";

// Candidates Profile Section

export const adminLogin = async (payload) => {
  try {
    return await axios.post(EndPoints.adminLogin, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
