import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";
import { deleteProfileLicense } from "../../../../../../api/candidate/candidate.class";

const ImageUploader = ({
  label,
  onChange,
  valuenew,
  name,
  value,
  setEdit,
  edit,
  disabled,
  onImageRemove,
}) => {
  const dispatch = useDispatch();
  const [image, showImage] = useState(false);
  const auth = useSelector((state) => state?.auth);
  const company = useSelector((state) => state?.company);

  // useEffect(() => {
  //   if (company?.updatedCompany?.success === false) {
  //     showImage(false);
  //   }
  // }, [company?.updatedCompany]);

  useEffect(() => {
    if (company?.updatedCompany?.success === false) {
      // Image removal detected, set image state to false
      showImage(false);
    } else if (!edit && valuenew) {
      // Check if valuenew is a File object before creating an object URL
      if (valuenew instanceof File) {
        showImage(URL.createObjectURL(valuenew));
      } else {
        showImage(valuenew);
      }
    } else if (edit && valuenew) {
      showImage(valuenew);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, valuenew, company?.updatedCompany?.success]);

  const handleRemoveImage = () => {
    // Hide the image
    showImage(false);
    // Call the callback function to handle image removal in the parent component
    onImageRemove();
    // Update the valuenew state to null (or an empty string) to indicate image removal
    onChange(null);
  };

  // console.log("image", image);
  // console.log("valuenew", valuenew);
  // console.log("edit", edit);

  return (
    <Box className={styles.imageUploaderWrapper}>
      <label>{label}</label>
      <Box className={styles.imageUploader}>
        <>
          {image ? (
            <>
              <Box className={styles?.crossImageBox}>
                <img
                  src={edit ? `${process.env.REACT_APP_URL}${valuenew}` : image}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "120px",
                    objectFit: "contain",
                  }}
                />

                <MdOutlineClose
                  className={styles.cross}
                  onClick={handleRemoveImage}
                />
              </Box>
            </>
          ) : (
            <>
              {" "}
              <label htmlFor="file-input">
                <Box className={styles.imageBox}>
                  {/* <Typography>Click to upload an image</Typography> */}
                </Box>
                <input
                  accept="image/*"
                  id="file-input"
                  disabled={disabled}
                  type="file"
                  value={value}
                  onChange={onChange}
                  name={name}
                />
              </label>
              <Typography>PNG or JPG file of 1500 x 500 pixels.</Typography>
            </>
          )}
        </>
      </Box>
    </Box>
  );
};

export default ImageUploader;
