import React from "react";
import { Optionlabel } from "../../../../store/actions/optionlabel";
import { Optionlabelvalue } from "../../../../store/actions/optionlabelvalue";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Deletecheckbox } from "../../../../store/actions/deletecheckbox";
function OptionTableRow({
  indexCount,
  setindexCount,
  optionLabel,
  setOptionLabel,
  deleteTableRows,
}) {
  let dispatch = useDispatch();
  const getlabel = useSelector((state) => state?.getlabelkey);
  const getlabelvalue = useSelector((state) => state?.getlabelvalue);
  const getcheckbox = useSelector((state) => state?.getcheckbox);
  const getcheckboxlist = useSelector((state) => state?.getcheckboxlist);
  return (
    <tr>
      <td>
        <input
          type="text"
          value={getlabel[indexCount - 1]?.value}
          placeholder="Enter label"
          name="olabel"
          className="form-control"
        />
      </td>
      <td>
        <input
          type="text"
          value={getlabelvalue[indexCount - 1]?.value}
          name="ovalue"
          className="form-control"
        />{" "}
      </td>
      <td>
        <button className="btn btn-outline-danger">x</button>
      </td>
    </tr>
  );
}

export default OptionTableRow;
