import { GET_DELETE_ID } from "../constants/constants";
import { v4 as uuid } from "uuid";
import $ from "jquery";
export const Getdeleteid = (id) => {
  $("#" + id)
    .closest(".dropItem")
    .remove();

  // return {
  //   type: GET_DELETE_ID,
  //   payload: id,
  // };
  //   dispatch({
  //     type: GET_DELETE_ID,
  //     // payload: { id },
  //   });
};
