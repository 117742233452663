import { useEffect, useRef, useState, React } from "react";
import CustomSelect from "../../../common/Select";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import { storeFormStructure } from "../../../../store/actions/storeFormStructure";
import { FORM_STRUCTURE_UPDATE } from "../../../../store/constants/constants";
import useDragField from "../../../../customHook/useDragField";
function SelectField_r({
  label,
  type,
  id,
  main_id,
  placeholder,
  name,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const auth = useSelector((state) => state.auth);
  const rprofile = useSelector((state) => state?.rprofile?.profile);
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  useEffect(() => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((item) => {
                if (item?.id === id) {
                  return {
                    ...item,
                    type: {
                      ...item.type,
                      options: item?.type?.options?.map((subdata, i) => {
                        if (i === 0) {
                          return {
                            ...subdata,
                            value:
                              rprofile.results.first_name +
                              " " +
                              rprofile.results.last_name,
                            label:
                              rprofile.results.first_name +
                              " " +
                              rprofile.results.last_name,
                          };
                        }
                        if (i === 1) {
                          return {
                            ...subdata,
                            value: rprofile.results.contact_number,
                            label: rprofile.results.contact_number,
                          };
                        }
                        if (i === 2) {
                          return {
                            ...subdata,
                            value: rprofile.results.email,
                            label: rprofile.results.email,
                          };
                        }
                      }),
                    },
                  };
                }
                return item;
              }),
            },
          },
        };
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const addtablerow = getpreviewform.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            type: {
              ...item.type,
              options: item?.type?.options?.map((subdata, i) => {
                if (i === 0) {
                  return {
                    ...subdata,
                    value:
                      rprofile.results.first_name +
                      " " +
                      rprofile.results.last_name,
                    label:
                      rprofile.results.first_name +
                      " " +
                      rprofile.results.last_name,
                  };
                }
                if (i === 1) {
                  return {
                    ...subdata,
                    value: "+" + rprofile.results.contact_number,
                    label: "+" + rprofile.results.contact_number,
                  };
                }
                if (i === 2) {
                  return {
                    ...subdata,
                    value: rprofile.results.email,
                    label: rprofile.results.email,
                  };
                }
              }),
            },
          };
        }
        return item;
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
    }
  }, []);

  const setSelectedOption = (value) => {
    if (two_col === 1) {
      const addtablerow2 = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((item) => {
                if (item?.id === id) {
                  return {
                    ...item,
                    type: {
                      ...item.type,
                      options: item?.type?.options?.map((subdata) => {
                        if (value === subdata.value) {
                          return {
                            ...subdata,
                            selec: true,
                          };
                        } else {
                          return {
                            ...subdata,
                            selec: false,
                          };
                        }
                      }),
                    },
                  };
                }
                return item;
              }),
            },
          },
        };
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
    } else {
      const addtablerow2 = getpreviewform.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            type: {
              ...item.type,
              options: item?.type?.options?.map((subdata) => {
                if (value === subdata.value) {
                  return {
                    ...subdata,
                    selec: true,
                  };
                } else {
                  return {
                    ...subdata,
                    selec: false,
                  };
                }
              }),
            },
          };
        }
        return item;
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
    }
  };

  let dispatch = useDispatch();

  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <label htmlFor="">{label}</label>
      <select
        id=""
        name={name}
        className={style.recruiterInfoField}
        onChange={(e) => setSelectedOption(e.target.value)}
      >
        <option disabled selected>
          {placeholder}
        </option>

        {}
        {two_col === 1
          ? getpreviewform.map((item) => {
              return item?.type[col_name]?.data?.map((subcol) => {
                if (subcol?.id === id) {
                  {
                    return subcol.type.options.map((subitem, index) => {
                      return subitem.selec === true ? (
                        <option selected value={subitem.value} key={index}>
                          {subitem.label}
                        </option>
                      ) : (
                        <option value={subitem.value} key={index}>
                          {subitem.label}
                        </option>
                      );
                    });
                  }
                }
              });
            })
          : getpreviewform.map((item) => {
              if (item?.id === id) {
                {
                  return item.type.options.map((option, index) => {
                    return option.selec === true ? (
                      <option selected value={option.value} key={index}>
                        {option.label}
                      </option>
                    ) : (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    );
                  });
                }
              }
            })}
      </select>
    </Box>
  );
}

export default SelectField_r;
