import { useEffect, useRef, useState, React } from "react";
import CustomSelect from "../../../common/Select";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import { storeFormStructure } from "../../../../store/actions/storeFormStructure";
import { FORM_STRUCTURE_UPDATE } from "../../../../store/constants/constants";
function SelectField_r_print({
  label,
  type,
  id,
  main_id,
  value,
  require,
  placeholder,
  name,
  two_col,
  col_name,
  SelectField,
}) {
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const auth = useSelector((state) => state.auth);

  let dispatch = useDispatch();

  return (
    <Box className={`${style.inputBox} dropItem`}>
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />

      {two_col === 1
        ? getpreviewform.map((item) => {
            return item?.type[col_name]?.data?.map((subcol) => {
              if (subcol.id === id) {
                {
                  return subcol.type.options.map((subitem, index) => {
                    return subitem.selec === true ? <p>{subitem.value}</p> : "";
                  });
                }
              }
            });
          })
        : getpreviewform.map((item) => {
            if (item.id === id) {
              {
                return item.type.options.map((option, index) => {
                  return option.selec === true ? <p>{option.value}</p> : "";
                });
              }
            }
          })}
    </Box>
  );
}

export default SelectField_r_print;
