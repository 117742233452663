import { GET_CHECKBOX_OPTION } from "../constants/constants";
import { v4 as uuid } from "uuid";
import $ from "jquery";
export const Getcheckboxoption = (value) => {
  return {
    type: GET_CHECKBOX_OPTION,
    payload: value,
    index: 0,
  };
};
