import React from "react";

import PopupModal from "./PopupModal.jsx";

/***
 * popup to ask password to decrypt metadata in document signing flow
 */
function AskPassword({
  decryptionPopup,
  onPassSubmit,
  passError,
  setDecPassword,
}) {
  return (
    <PopupModal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {}}
      open={decryptionPopup}
      showCloseIcon={false}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onPassSubmit();
        }}>
        <div className='form-group'>
          <label htmlFor='forDoctitle'>Decryption Password </label>
          <input
            aria-describedby='forDoctitle'
            autoComplete='off'
            className='form-control text-dark'
            id='forDoctitle'
            name='title'
            onChange={(e) => setDecPassword(e.target.value)}
            placeholder='Enter password to load pdf'
            type='password'
          />
          <small className='form-text text-danger' id='emailHelp'>
            {passError || ""}
          </small>
        </div>
        <div className='button_box'>
          <button type='submit'>Submit</button>
        </div>
      </form>
    </PopupModal>
  );
}

export default AskPassword;
